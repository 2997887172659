import { BaseAPI, Configuration, ConfigurationParameters, LoginApi, UserApi, ResourceApi, ImageApi, OrderApi } from "../client";
import { SessionStore } from "../data/SessionStore";
import NetworkConstants from "../constants/Network";

export enum Api {
    LoginApi, UserApi, ResourceApi, ImageApi, OrderApi
}

export class ApiClient {

    protected static config(token?: string | undefined, forceJSON: boolean | undefined = true): Configuration {
        const params: ConfigurationParameters = {};
        if (token) params.accessToken = token;

        params.basePath = NetworkConstants.BACKEND
        forceJSON && (params.headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        });
        return new Configuration(params);
    }

    public static getClient(type: Api): BaseAPI {

        switch (type) {
            case Api.LoginApi:
                return new LoginApi(this.config(SessionStore.getToken()));
            case Api.UserApi:
                return new UserApi(this.config(SessionStore.getToken()));
            case Api.ResourceApi:
                return new ResourceApi(this.config(SessionStore.getToken()));   
            case Api.ImageApi:
                return new ImageApi(this.config(SessionStore.getToken(), false));   
            case Api.OrderApi:
                return new OrderApi(this.config(SessionStore.getToken(), false));   
            default:
                throw new NotImplementedException(undefined);
        }

    }

}

class NotImplementedException extends Error {
    constructor(value: Api | undefined) {
        super("Api not implemented: " + value?.toString);
    }
}