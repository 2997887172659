import { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { Dialog, Grid, IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useShinpadTemplateStyles } from '../styles/styles';
import { useTranslation } from 'react-i18next';

type TMaximizeImage = {
    open: boolean
    images: any[]
    setOpen: (s: boolean) => void
}

export const MaximizeImage = ({ open, images, setOpen }: TMaximizeImage) => {
    const classes = useShinpadTemplateStyles();
    const { t } = useTranslation();
    const isMobileLow = useMediaQuery('(max-width:425px)');
    const [index, setIndex] = useState(0);

    const moveRight = () => {
        if (index >= images.length - 1) {
            setIndex(0)
        } else {
            setIndex(index + 1);
        }
    }

    const moveLeft = () => {
        if (index === 0) {
            setIndex(images.length - 1)
        } else {
            setIndex(index - 1);
        }
    }

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={'md'}
            onClose={() => setOpen(false)} >
            <Grid container className={isMobileLow? classes.carrouselMainGridMobile: classes.carrouselMainGrid }>
            {images.length < 1 ?
                    <Grid item style={{ textAlign: "center", margin: "auto" }}>
                        <img src="https://media.giphy.com/media/WvuTFk2IN7jxoLVDkP/giphy.gif" width={200} height={200} />
                        <p>{t("3dpreview.message")}</p>
                    </Grid>
                    :
                    <Grid item xs={12} className={classes.carrouselGrid}>
                        {images.length === 3 &&
                        <IconButton style={{ border: "1px solid black" }} onClick={moveLeft}>
                            <ChevronLeft fontSize="large" />
                        </IconButton>}

                        <Grid item xs={12} className={isMobileLow? classes.carrouselImageGridMobile: classes.carrouselImageGrid}>
                            <img className={classes.carrouselImage} src={images[index]} alt={"Error displaying model"} />
                        </Grid>
                        {images.length === 3 && <IconButton style={{ border: "1px solid black" }} onClick={moveRight}>
                            <ChevronRight fontSize="large" />
                        </IconButton>}
                    </Grid>
                }
            </Grid>
        </Dialog >
    )
}