import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Button, RadioGroup } from "@movicoders/movicoders-components";
import { displayPosition } from "@movicoders/movicoders-components/dist/radiogroup/RadioGroup.interface";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Order,
  OrderStatusEnum,
  UpdateOrderByIdStatusEnum,
} from "../../../client";
import { OrdersViewmodel } from "../../../viewmodels/OrdersViewModel";
import { useManageOrdersStyles } from "./styles/styles";
import OwnColors from "../../../constants/OwnColors";
import { generateWCOrder } from "../../../helpers/OrderProcessHelper";

type TStatusDialog = {
  open: boolean;
  setOpen: (value: boolean) => void;
  order?: Order;
  loadData: () => void;
  openSnackbar: (open: boolean, error: boolean, message: string) => void;
};
const StatusDialog = ({
  open,
  setOpen,
  order,
  loadData,
  openSnackbar,
}: TStatusDialog) => {
  const { t } = useTranslation();
  const classes = useManageOrdersStyles();
  const [status, setStatus] = useState(order?.status);

  useEffect(() => {
    setStatus(order?.status);
  }, [order]);

  const options = [
    {
      id: "0",
      label: t("manageOrdersView.status.PENDING"),
      value: OrderStatusEnum.PENDING,
    },
    {
      id: "1",
      label: t("manageOrdersView.status.CANCELLED"),
      value: OrderStatusEnum.CANCELLED,
    },
    {
      id: "2",
      label: t("manageOrdersView.status.CONFIRMED"),
      value: OrderStatusEnum.CONFIRMED,
    },
    {
      id: "3",
      label: t("manageOrdersView.status.FINISHED"),
      value: OrderStatusEnum.FINISHED,
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    OrdersViewmodel.getInstance()
      .updateOrder(order?.id!, status?.toString() as UpdateOrderByIdStatusEnum)
      .then((res) => {
        let userId: string | null = localStorage.getItem("userId");
        openSnackbar(true, false, t("manageOrdersView.udpate.success"));
        if (res && res.status === OrderStatusEnum.CONFIRMED) {
          generateWCOrder(
            {
              size: res.size ?? "",
              extra: res.extra ?? "",
              quantity: res.quantity ?? 1,
              type: t("product.carbon.normal"),
            },
            parseInt(userId!),
            res.finalDesign![0].bucketUrl ?? "",
            res.finalDesign![1].bucketUrl ?? ""
          );
        }
        loadData();
        handleClose();
      })
      .catch((err) => {
        openSnackbar(true, true, t("manageOrdersView.udpate.error"));
      });
  };

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={open} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        {t("manageOrdersView.status.change")}
      </DialogTitle>
      <DialogContent>
        <Grid item spacing={2} style={{ marginTop: "5px" }}>
          <RadioGroup
            options={options}
            onSelectionChange={(target: any) => {
              setStatus(target);
            }}
            display={displayPosition.vertical}
            selection={status}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          text={t("manageUsersView.form.cancel")}
          onClick={handleClose}
          style={{ fontWeight: "bold" }}
          textColor="white !important"
          hoverColor="white !important"
          backgroundColor={`${OwnColors.buttonBlack} !important`}
          hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`}
        />
        <Button
          text={t("manageUsersView.form.save")}
          onClick={handleSave}
          style={{ fontWeight: "bold" }}
          textColor="white !important"
          hoverColor="white !important"
          backgroundColor={`${OwnColors.buttonBlack} !important`}
          hoverBackgroundColor={`${OwnColors.buttonBlackHover}  !important`}
        />
      </DialogActions>
    </Dialog>
  );
};

export default StatusDialog;
