export const ApplicationPaths = {
    LOGIN: "/login",
    HOME: "/home",
    MANAGE_USERS: "/users",
    ORDERS: "/orders",
    MANAGE_RESOURCES: "/resources",
    USER_EDIT: "/edit-user",
    RESTRICTED_PATH: '/restricted'
}

export const ToolbarHeight = "64px"

export const shinpadSizes = ["XXXXS", "XXXS", "XXS", "XS", "S", "M", "L"];
export const shinpadOptionals = ["blancas", "negras", "no"];

export enum Application_PROFILE {
	PROFILE_ADMIN = 'ADMIN',
	PROFILE_SHOP = 'SHOP'
};