import Konva from "konva";
import { Fragment, useEffect, useRef, useState } from "react";
import { Text, } from "react-konva";
import useLongPress from "../../../customHooks/useLongPress";

type TTextContainer = {
    textShape: any,
    isSelected: boolean,
    onSelect: () => void,
    onChange: (newAttr: any) => void,
    bold: boolean,
    italic: boolean,
    underlined: boolean,
    shadow: boolean,
    font: string,
    color: string
    draggable?: boolean
    handleContextMenu: (event: any) => void,
    trRef: any
}
export const TextContainer = ({ textShape, isSelected, onSelect, onChange, bold, italic, shadow, underlined, font, color, draggable = true, handleContextMenu, trRef }: TTextContainer) => {
    const textRef = useRef<Konva.Label>();

    /**
        * If the shape is selected links the nodes of the transformer to the nodes
        * of the shape. When the nodes are moved the shape is resized.
        */
    useEffect(() => {
        if (isSelected && draggable) {
            trRef!.current!.nodes([textRef.current!]);
        }
    }, [isSelected, bold, italic, font, underlined, shadow]);

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };

    const longPressEvent = useLongPress(handleContextMenu, onSelect, defaultOptions);

    return (
        <Fragment>
            <Text
                //This coordinates are provisional a can be changed any moment
                x={textShape.x}
                y={textShape.y}
                text={textShape.text}
                fontSize={30}
                fontFamily={font}
                fontStyle={`${italic ? "italic" : ""}${bold ? " bold" : ""}`}
                textDecoration={underlined ? "underline" : ""}
                shadowColor={shadow? 'black' : 'none'}
                shadowBlur={shadow? 3.5 : 0}
                shadowOffsetX={shadow? 2 : 0}
                shadowOffsetY={shadow? 2 : 0}
                shadowOpacity={shadow? 0.7 : 0}

                fill={color}

                //this coordinates are provisional a can be changed any moment
                draggable={draggable}
                onDragEnd={(e) => {
                    onChange({
                        ...textShape, //This line doesn't cause any problem but seems to do nothing relevant
                        x: e.target.x(),
                        y: e.target.y()
                    })

                }}
                onClick={onSelect}
                onTap={onSelect}
                ref={textRef}
                onTransformEnd={(e) => {
                    const node = textRef.current;

                    //Sets the new coordinates and dimensions while transforming
                    onChange({
                        ...textShape,
                        x: node!.x(),
                        y: node!.y(),
                        scaleX: node?.scaleX(),
                        scaleY: node?.scaleY(),
                        rotation: node?.rotation(),
                    })
                }}
                onContextMenu={handleContextMenu}
                {...longPressEvent}
                {...textShape}
            />
        </Fragment>
    )
}