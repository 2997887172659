import { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { buildInputFile, execute } from "wasm-imagemagick";

//Imported Components
import model3dFront from "../../assets/images/3dmodelOpacity.png";
import model3dSide from "../../assets/images/3dmodelOpacity_side.png";
import model3dSide2 from "../../assets/images/3dmodelOpacity_side2.png";
import Konva from "konva";
import IImage from "../../interfaces/IImage";
import IText from "../../interfaces/IText";
import { observer } from "mobx-react";

import EditOptions from "./components/editOptions/EditOptions";
import {
  LeftMenuMobile,
  ProductMenu,
  BackgroundMenu,
  TextMenu,
  ImageMenu,
  ElementsMenu,
} from "./components/leftmenuMobile";
import { MaximizeImage } from "./components/carrousel/MaximizeImage";
import ShinpadStage from "./components/ShinpadStage";
import { ResourceViewModel } from "../../viewmodels/ResourceViewModel";
import { GetResourceByTypeTypeEnum, Order, Resource } from "../../client";
import CustomToolbar from "../../components/skeleton/toolbar/CustomToolbar";
import { useShinpadTemplateStyles } from "./components/styles/styles";
import OwnColors from "../../constants/OwnColors";
import ModelDim from "../../constants/ModelDim";
import ProductMenuAttributes from "../../interfaces/ProductMenuAttributes";
import { manageOrder } from "../../helpers/OrderProcessHelper";
import SnackBar from "../../components/snackbar/Snackbar";
import { useTranslation } from "react-i18next";
import { LayersMenu } from "./components/leftmenuMobile/subMenus/LayersMenu";
import FinishPurchaseDialog from "./components/FinishPurchaseDialog";
import { downloadURI } from "../../helpers/DownloadHelper";
import { DeleteDialog } from "./components/DeleteDialog";

const ShinpadTemplateView = () => {
  const { t } = useTranslation();
  const classes = useShinpadTemplateStyles();
  const stageRef = useRef<Konva.Stage>(null);
  const stageRef2 = useRef<Konva.Stage>(null);
  const [readableTemplate, setReadable] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState([
    OwnColors.defaultBackground,
  ]);
  const [items, setItems] = useState<(IImage | IText)[]>([]);
  const [items2, setItems2] = useState<(IImage | IText)[]>([]);
  const [selectedId, selectImage] = useState<string | null>(null);
  const [isTextSelected, selectText] = useState<boolean>(false);
  const idCount = useRef<number>(0);
  const [leftActive, setLeftActive] = useState(true);
  const [tab, setTab] = useState<number>(1);
  const [productAttributes, setProductAttributes] =
    useState<ProductMenuAttributes>({
      size: "S",
      extra: "no",
      quantity: 1,
      type: t("product.carbon.normal"),
    }); //Default attributes
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  //Model Visor state constants
  const [openPreview, setOpenPreview] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [images, setImages] = useState<any[]>([]);
  const [showTemplate, setShowTemplate] = useState(true);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase()
    if (userAgent.includes("mobi") || userAgent.includes("tablet")) {
      const url = process.env.REACT_APP_MOBILE_URL ?? ""
      window.location.replace(url)
    }
  }, [])

  useEffect(() => {
    ResourceViewModel.getInstance()
      .getResourcesByType(GetResourceByTypeTypeEnum.BACKGROUND)
      .then((res: Resource[] | undefined) => {
        res && ResourceViewModel.getInstance().setBackgrounds(res);
      });
    ResourceViewModel.getInstance()
      .getResourcesByType(GetResourceByTypeTypeEnum.ELEMENT)
      .then((res: Resource[] | undefined) => {
        res && ResourceViewModel.getInstance().setElements(res);
      });
  }, []);

  /**
   * When showBigImage turns true calls setModelArray() when it turns false clears the array for future new images
   */
  useEffect(() => {
    if (openPreview || openConfirm) {
      setModelArray();
    } else {
      setImages([]);
    }
  }, [openPreview, openConfirm]);

  const handleOpenSnackbar = (message: string) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  /**
   * Creates an array of url from images of the models created by calling the method createModel() to generate them
   */
  async function setModelArray(onlyFront?: boolean) {
    setReadable(true);
    try {
      await selectImage(null);
      await selectText(false);
      const uriLeft = stageRef2!.current!.toDataURL({ pixelRatio: 4 });
      const uriRight = stageRef!.current!.toDataURL({ pixelRatio: 4 });
      await createModel(
        uriLeft,
        uriRight,
        model3dFront,
        `convert -size 1920x1443 xc:white ( ( right_design.png -resize ` +
        ModelDim.DESIGN_DIM +
        `! ) -fuzz ` +
        ModelDim.DESIGN_FUZZ +
        ` -bordercolor white -border 1 -fill none -draw 'alpha 0,0 floodfill' -shave 1x1 ) -geometry ` +
        ModelDim.DESIGN_COORD_LEFT_FRONT +
        ` -composite ( ( left_design.png -resize ` +
        ModelDim.DESIGN_DIM +
        `! ) -fuzz ` +
        ModelDim.DESIGN_FUZZ +
        `% -bordercolor white -border 1 -fill none -draw 'alpha 0,0 floodfill' -shave 1x1 ) -geometry ` +
        ModelDim.DESIGN_COORD_RIGHT_FRONT +
        ` -composite 3dmodelOpacity.png -geometry +0+0 -composite designerResult.png`
      );
      if (!onlyFront) {
        await createModel(
          uriLeft,
          uriRight,
          model3dSide,
          `convert -size 1920x1443 xc:white ( ( left_design.png -resize ` +
          ModelDim.DESIGN_DIM +
          `! ) -fuzz ` +
          ModelDim.DESIGN_FUZZ +
          `% -bordercolor white -border 1 -fill none -draw 'alpha 0,0 floodfill' -shave 1x1 ) -geometry ` +
          ModelDim.DESIGN_COORD_RIGHT_SIDE +
          ` -composite ( ( right_design.png -resize ` +
          ModelDim.DESIGN_DIM +
          `! ) -fuzz ` +
          ModelDim.DESIGN_FUZZ +
          ` -bordercolor white -border 1 -fill none -draw 'alpha 0,0 floodfill' -shave 1x1 ) -geometry ` +
          ModelDim.DESIGN_COORD_LEFT_SIDE +
          ` -composite 3dmodelOpacity.png -geometry +0+0 -composite designerResult.png`
        );
        await createModel(
          uriLeft,
          uriRight,
          model3dSide2,
          `convert -size 1920x1443 xc:white ( ( right_design.png -resize ` +
          ModelDim.DESIGN_DIM +
          `! ) -fuzz ` +
          ModelDim.DESIGN_FUZZ +
          ` -bordercolor white -border 1 -fill none -draw 'alpha 0,0 floodfill' -shave 1x1 ) -geometry ` +
          ModelDim.DESIGN_COORD_LEFT_SIDE2 +
          ` -composite ( ( left_design.png -resize ` +
          ModelDim.DESIGN_DIM +
          `! ) -fuzz ` +
          ModelDim.DESIGN_FUZZ +
          `% -bordercolor white -border 1 -fill none -draw 'alpha 0,0 floodfill' -shave 1x1 ) -geometry ` +
          ModelDim.DESIGN_COORD_RIGHT_SIDE2 +
          ` -composite 3dmodelOpacity.png -geometry +0+0 -composite designerResult.png`
        );
      }
    } catch (error) {
      setOpenPreview(false);
      handleOpenSnackbar("3dpreview.error");
    }
    setReadable(false);
  }

  /**
   * Creates a model in 3d of espinillera using imageMagick and adds them to the image array
   * @param uri - url of the right espinillera
   * @param uri2 - url of the left espinillera
   * @param base - basic model used for the model view
   * @param commands - image magick commands to do the transformation
   */
  const createModel = async (
    uri: string,
    uri2: string,
    base: string,
    commands: string
  ) => {
    const { stderr, stdout, outputFiles, exitCode } = await execute({
      inputFiles: [
        await buildInputFile(base, "3dmodelOpacity.png"),
        await buildInputFile(uri, "left_design.png"),
        await buildInputFile(uri2, "right_design.png"),
      ],
      commands: [commands],
    });
    images.push(URL.createObjectURL(outputFiles[0]["blob"]));
  };

  //MODEL VISOR CODE -------------------------------------------------------------------------------------------------------------------------------------------

  const addText = (value: string) => {
    if (!value.match("^ *$")) {
      let x = stageRef.current!.width() / 2;
      let y = stageRef.current!.height() / 2;
      leftActive
        ? setItems([
          ...items,
          {
            x: x,
            y: y,
            id: "text_" + idCount.current,
            text: value,
            bold: false,
            italic: false,
            underlined: false,
            shadow: false,
            font: "Calibri",
            color: "#000000",
          },
        ])
        : setItems2([
          ...items2,
          {
            x: x,
            y: y,
            id: "text_" + idCount.current,
            text: value,
            bold: false,
            italic: false,
            underlined: false,
            shadow: false,
            font: "Calibri",
            color: "#000000",
          },
        ]);
      idCount.current += 1;
    }
  };

  const addImage = (url: string, extension?: string) => {
    if (url) {
      let finalExtension = extension ?? url.split(".").pop();
      var imgSup = new Image();
      imgSup.src = url;
      imgSup.onload = () => {
        let x = stageRef.current!.width() / 2;
        let y = stageRef.current!.height() / 2;
        imgSup.width /= 3;
        imgSup.height /= 3;
        let stageWidth = stageRef.current?.width() || 0;
        let stageHeight = stageRef.current?.height() || 0;
        if (imgSup.width > stageWidth || imgSup.height > stageHeight) {
          let ratio =
            Math.min(stageWidth / imgSup.width, stageHeight / imgSup.height) -
            0.06;
          imgSup.width *= ratio;
          imgSup.height *= ratio;
        }
        leftActive
          ? setItems([
            ...items,
            {
              x: x,
              y: y,
              extension: finalExtension ?? "",
              src: url,
              id: "image_" + idCount.current,
              width: imgSup.width,
              height: imgSup.height,
            },
          ])
          : setItems2([
            ...items2,
            {
              x: x,
              y: y,
              extension: extension ?? "",
              src: url,
              id: "image_" + idCount.current,
              width: imgSup.width,
              height: imgSup.height,
            },
          ]);
        setItemId(idCount.current + 1);
      };
    }
  };

  const setItemId = (value: number) => {
    idCount.current = value;
  };

  const handleBuy = async () => {
    await setShowTemplate(false);
    const leftImage = await stageRef.current?.toDataURL({ pixelRatio: 4 });
    const rightImage = await stageRef2.current?.toDataURL({ pixelRatio: 4 });
    manageOrder(
      productAttributes,
      items,
      items2,
      backgroundColor,
      leftImage,
      rightImage,
      images[0]
    )
      ?.then((res: any) => {
        let userId: string | null = localStorage.getItem("userId");
        if (res && userId !== null) {
          setOpenConfirm(false);
          setOrderId((res[3] as Order).id?.toString() ?? "");
          setOpenSuccessSnackbar(true);
        }
      })
      ?.catch((err) => {
        setOpenConfirm(false);
        setSnackbarMessage("buy.error");
        setOpenSnackbar(true);
      })
      ?.finally(() => {
        setBackgroundColor([OwnColors.defaultBackground]);
        setItems([]);
        setItems2([]);
        setProductAttributes({
          size: "S",
          extra: "no",
          quantity: 1,
          type: "Normal",
        });
        setShowTemplate(true);
      });
  };

  const handleDownloadImage = async () => {
    await setShowTemplate(false);
    new Promise((resolve, reject) => {
      try {
        const leftImage = stageRef.current?.toDataURL({ pixelRatio: 4 });
        const rightImage = stageRef2.current?.toDataURL({ pixelRatio: 4 });
        downloadURI(leftImage!, "izquierda");
        downloadURI(rightImage!, "derecha");
        resolve(true);
      } catch (error) {
        reject(false);
      }
    }).finally(() => {
      setShowTemplate(true);
    });
  };

  const handleOpenConfirmDialog = async () => {
    await selectImage(null);
    await selectText(false);
    setOpenConfirm(true);
  };

  const changeActive = (stage: number) => {
    setLeftActive(stage === 1);
  };

  const handleDelete = () => {
    setItems([]);
    setItems2([]);
    setOpenDelete(false);
  };

  return (
    <Grid>
      <CustomToolbar
        startPreview={() => setOpenPreview(true)}
        handleBuy={handleOpenConfirmDialog}
      />
      <Grid container className={classes.parentContainer}>
        <Grid item sm={1}>
          <LeftMenuMobile setTab={setTab} tab={tab} />
        </Grid>
        <Grid
          item
          xs={3}
          container
          style={{
            visibility: tab ? "visible" : "hidden",
            opacity: tab ? "1" : "0",
            transform: tab ? "translateX(0px)" : "translateX(calc(-465px))",
            transition:
              "visibility .25s ease-in-out,opacity .25s ease-in-out,-webkit-transform .25s ease-in-out",
          }}
        >
          <Grid item xs={12} className={classes.leftMenuContainer}>
            {
              {
                1: (
                  <ProductMenu
                    changeTab={setTab}
                    attributes={productAttributes}
                    setAttributes={setProductAttributes}
                  />
                ),
                2: (
                  <BackgroundMenu
                    setBackgroundColor={setBackgroundColor}
                    backgroundColor={backgroundColor[0]}
                    changeTab={setTab}
                  />
                ),
                3: <ElementsMenu addImage={addImage} changeTab={setTab} />,
                4: <ImageMenu addImage={addImage} changeTab={setTab} />,
                5: <TextMenu addText={addText} changeTab={setTab} />,
                6: (
                  <LayersMenu
                    leftItems={items}
                    rightItems={items2}
                    changeTab={setTab}
                    setLeftItems={setItems}
                    setRightItems={setItems2}
                    handleBuy={handleOpenConfirmDialog}
                  />
                ),
              }[tab]
            }
          </Grid>
        </Grid>
        <Grid item container sm={8}>
          <EditOptions
            item={
              leftActive
                ? (items.find((i) => i.id === selectedId) as IText)
                : (items2.find((i) => i.id === selectedId) as IText)
            }
            items={items}
            items2={items2}
            setItems={setItems}
            setItems2={setItems2}
            leftActive={leftActive}
            selectedId={selectedId}
            isTextSelected={isTextSelected}
            setItemId={setItemId}
            selectImage={selectImage}
            selectText={selectText}
            background={backgroundColor}
            setBackground={setBackgroundColor}
            handleOpenSnackbar={handleOpenSnackbar}
            handleDownloadImage={handleDownloadImage}
            setOpenDelete={setOpenDelete}
          />
          <Grid
            item
            xs={6}
            onClick={() => changeActive(1)}
            onTouchStart={() => changeActive(1)}
            style={{ height: "100%" }}
          >
            <ShinpadStage
              readableTemplate={readableTemplate}
              stageRef={stageRef}
              items={items}
              setItems={setItems}
              selectedId={selectedId}
              selectImage={selectImage}
              selectText={selectText}
              backgroundColor={backgroundColor[0]}
              leftTemplate={true}
              isDraggable={leftActive}
              showTemplate={showTemplate}
            />
          </Grid>
          <Grid
            item
            xs={6}
            onClick={() => changeActive(2)}
            onTouchStart={() => changeActive(2)}
            style={{ height: "100%" }}
          >
            <ShinpadStage
              readableTemplate={readableTemplate}
              stageRef={stageRef2}
              items={items2}
              setItems={setItems2}
              selectedId={selectedId}
              selectImage={selectImage}
              selectText={selectText}
              backgroundColor={backgroundColor[0]}
              leftTemplate={false}
              isDraggable={!leftActive}
              showTemplate={showTemplate}
            />
          </Grid>
        </Grid>
        {
          <MaximizeImage
            images={images}
            open={openPreview}
            setOpen={setOpenPreview}
          />
        }
      </Grid>
      <FinishPurchaseDialog
        open={openConfirm}
        onClose={() => {
          setOpenConfirm(false);
        }}
        handleConfirm={handleBuy}
        productAttributes={productAttributes}
        images={images}
      />
      <DeleteDialog
        delete={handleDelete}
        onClose={() => setOpenDelete(false)}
        open={openDelete}
      />
      <SnackBar
        open={openSnackbar}
        message={t(snackbarMessage)}
        severity={"error"}
        onClose={() => setOpenSnackbar(false)}
      />
      <SnackBar
        open={openSuccessSnackbar}
        message={t("buy.finish", { id: orderId })}
        severity={"success"}
        onClose={() => setOpenSuccessSnackbar(false)}
      />
    </Grid>
  );
};

export default observer(ShinpadTemplateView);
