import React, { Fragment, LegacyRef, useRef, useEffect } from 'react';
import { Stage, Layer, Transformer } from 'react-konva';
import useImage from 'use-image';
import { Image as ImageKonva } from 'react-konva';
import leftTemplateImage from '../../../assets/images/Lshinpad.svg';
import rightTemplateImage from '../../../assets/images/Rshinpad.svg';
import Background from './Background';
import { KonvaEventObject } from 'konva/lib/Node';
import Konva from 'konva';
import { ImageFromFile } from './ImageFromFile';
import { TextContainer } from './TextContainer';
import IImage from '../../../interfaces/IImage';
import IText from '../../../interfaces/IText';
import ContextMenu from './ContextMenu';
import { useShinpadTemplateStyles } from './styles/styles';
import OwnColors from '../../../constants/OwnColors';

type TShinpadStage = {
    readableTemplate: boolean
    stageRef: LegacyRef<Konva.Stage> | undefined
    items: (IImage | IText)[]
    setItems: (value: (IImage | IText)[]) => void
    selectedId: string | null
    selectImage: (value: string | null) => void
    selectText: (value: boolean) => void
    backgroundColor: string
    leftTemplate: boolean
    isDraggable?: boolean
    showTemplate?: boolean
}
const ShinpadStage = ({ readableTemplate, stageRef, items, setItems, selectedId, selectImage, selectText, backgroundColor, leftTemplate, isDraggable = true, showTemplate }: TShinpadStage) => {
    const classes = useShinpadTemplateStyles();
    const [leftTemplateShinpad] = useImage(leftTemplateImage);
    const [rightTemplateShinpad] = useImage(rightTemplateImage);
    const [contextMenu, setContextMenu] = React.useState<{ mouseX: number; mouseY: number; } | null>(null);
    const trRef = useRef<Konva.Transformer>(null);
    const width = (leftTemplateShinpad?.width ?? 1);
    const height = (leftTemplateShinpad?.height ?? 1);
    const [ratio, setRatio] = React.useState(1);

    React.useEffect(() => {
        if (width !== 1 && height !== 1) {
            const checkSize = () => {
                let scale = Math.min(((window.innerWidth / 3)) / width, (window.innerHeight - 117) / height) - 0.03;
                setRatio(scale);
            };
            window.addEventListener('resize', checkSize);
            let scale = Math.min(((window.innerWidth / 3)) / width, (window.innerHeight - 117) / height) - 0.03;
            setRatio(scale);
        }
    }, [width, height]);

    const LeftTemplate = () => {
        return <ImageKonva image={leftTemplateShinpad} listening={readableTemplate} height={height} width={width} scaleX={ratio} scaleY={ratio} />;
    }

    const RightTemplate = () => {
        return <ImageKonva image={rightTemplateShinpad} listening={readableTemplate} height={height} width={width} scaleX={ratio} scaleY={ratio} />;
    }

    const checkDeselect = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            selectImage(null);
            selectText(false);
        }
    }

    const handleContextMenu = (event: any) => {
        event.evt.preventDefault();
        setContextMenu(contextMenu === null ?
            {
                mouseX: (event.evt.clientX ?? event.evt.touches[0].clientX) - 2,
                mouseY: (event.evt.clientY ?? event.evt.touches[0].clientY) - 4
            }
            : null);
    };

    const handleMoveToFront = () => {
        if ((selectedId!.match("^image_[0-9]+$")) || (selectedId!.match("^text_[0-9]+$"))) {
            const itemsSupp = items.slice(parseInt(selectedId!)); //Creates a copy of the current array of items
            const itemSupp = itemsSupp.find(i => i.id === selectedId); //Creates a copy of the selected item
            if (itemsSupp.indexOf(itemSupp!) === itemsSupp.length - 1) return;
            itemsSupp.splice(itemsSupp.indexOf(itemSupp!), 1); //Removes the item from its old position
            itemsSupp.push(itemSupp!); // Pushes the item into the array
            setItems(itemsSupp); //saves the new state of the array
        }
    }

    const handleMoveUp = () => {
        const itemsSupp = items.slice(parseInt(selectedId!));
        const itemSupp = itemsSupp.find(i => i.id === selectedId);
        let initialIndex = itemsSupp.indexOf(itemSupp!)
        if (initialIndex === itemsSupp.length - 1) return;
        let finalIndex = initialIndex + 1;
        itemsSupp.splice(finalIndex, 0, itemsSupp.splice(initialIndex, 1)[0]);
        setItems(itemsSupp);
    }

    const handleMoveToBack = () => {
        if ((selectedId!.match("^image_[0-9]+$")) || (selectedId!.match("^text_[0-9]+$"))) {
            const itemsSupp = items.slice(parseInt(selectedId!));
            const itemSupp = itemsSupp.find(i => i.id === selectedId);
            if (itemsSupp.indexOf(itemSupp!) === 0) return;
            itemsSupp.splice(itemsSupp.indexOf(itemSupp!), 1);
            setItems([itemSupp!, ...itemsSupp]);
        }
    }

    const handleMoveDown = () => {
        const itemsSupp = items.slice(parseInt(selectedId!));
        const itemSupp = itemsSupp.find(i => i.id === selectedId);
        let initialIndex = itemsSupp.indexOf(itemSupp!)
        if (initialIndex === 0) return;
        let finalIndex = initialIndex - 1;
        itemsSupp.splice(finalIndex, 0, itemsSupp.splice(initialIndex, 1)[0]);
        setItems(itemsSupp);
    }

    const handleDelete = () => {
        const itms = items.slice();
        const itemSupp = itms.find(i => i.id === selectedId);
        itms.splice(itms.indexOf(itemSupp!), 1);
        setItems(itms);
        selectImage(null);
        selectText(false);
    }

    const handleClose = () => {
        setContextMenu(null);
    };

    return (
        <Fragment>
            <Stage
                id='editionPanel'
                width={width * ratio}
                height={height * ratio}
                className={isDraggable ? classes.selectedStage : classes.stage}
                ref={stageRef!}
                onMouseDown={checkDeselect}
                onTouchStart={checkDeselect} //For mobile
            >
                <Layer>
                    <Background
                        color={`${backgroundColor}`}
                        key={1}
                        stageHeight={height * ratio}
                        stageWidth={width * ratio}
                        onSelect={() => {
                            selectImage(null);
                            selectText(false);
                        }} />
                    {items.map((item) => {
                        if (item.id.match("^image_[0-9]+$")) {
                            return <ImageFromFile
                                trRef={trRef}
                                image={item}
                                key={item.id}
                                isSelected={item.id === selectedId}
                                draggable={isDraggable}
                                onSelect={() => {
                                    if (isDraggable) {
                                        selectImage(item.id);
                                        selectText(false);
                                    }
                                }}
                                onChange={(newAttr) => {
                                    if (isDraggable) {
                                        const itms = items.slice();
                                        itms[itms.findIndex(i => i.id === item.id)] = newAttr;
                                        setItems(itms);
                                    }
                                }}
                                handleContextMenu={handleContextMenu}
                            />;

                        } else if (item.id.match("^text_[0-9]+$")) {
                            return <TextContainer
                                trRef={trRef}
                                textShape={item}
                                key={item.id}
                                bold={(item as IText).bold}
                                italic={(item as IText).italic}
                                underlined={(item as IText).underlined}
                                shadow={(item as IText).shadow}
                                font={(item as IText).font}
                                color={(item as IText).color}
                                isSelected={item.id === selectedId}
                                draggable={isDraggable}
                                onSelect={() => {
                                    if (isDraggable) {
                                        selectImage(item.id);
                                        selectText(true);
                                    }
                                }}
                                onChange={(newAttr) => {
                                    if (isDraggable) {
                                        const itms = items.slice();
                                        itms[itms.findIndex(i => i.id === item.id)] = newAttr;
                                        setItems(itms);
                                    }
                                }}
                                handleContextMenu={handleContextMenu}
                            />
                        } else {
                            return <h1>ERROR</h1>;
                        }
                    })}
                    { showTemplate && (leftTemplate  ? <LeftTemplate /> : <RightTemplate />)}
                    {(selectedId !== null && items.find((value) => value.id === selectedId)) && <Transformer ref={trRef} anchorCornerRadius={50} anchorStroke={OwnColors.buttonBlack} borderStroke={OwnColors.buttonBlack} borderStrokeWidth={2} anchorSize={15} />}
                </Layer>
            </Stage>
            <ContextMenu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorPosition={contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined}
                moveToFront={handleMoveToFront}
                moveToBack={handleMoveToBack}
                moveUp={handleMoveUp}
                moveDown={handleMoveDown}
                onDelete={handleDelete}
            />
        </Fragment>
    );
}

export default ShinpadStage;