import WCOrder from "../interfaces/WCOrder2";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import ProductMenuAttributes from "../interfaces/ProductMenuAttributes";
import IImage from "../interfaces/IImage";
import IText from "../interfaces/IText";
import { Image, Order, OrderStatusEnum } from "../client";
import { shinpadOptionals } from "../constants/Application";
import { UserViewModel } from "../viewmodels/UserViewModel";
import { OrdersViewmodel } from "../viewmodels/OrdersViewModel";
import { getBase64FromUrl } from "./DownloadHelper";
import { ImageViewModel } from "../viewmodels/ImageViewModel";

const devApi = new WooCommerceRestApi({
  url: process.env.REACT_APP_WOOCOMMERCE_URL ?? "",
  consumerKey: process.env.REACT_APP_WOOCOMMERCE_KEY ?? "",
  consumerSecret: process.env.REACT_APP_WOOCOMMERCE_SECRET ?? "",
  version: "wc/v3",
});

export const getItemPrice = async (item: number) => {
  return await devApi
    .get("products/" + item)
    .then((data) => data.data.price)
    .catch(
      (e) => "No se pudo recuperar el precio, por favor vuelva a intentarlo"
    );
};
/**
 * Woocommerce API connection data (Url, user, key)
 * Must be into database
 */
const api = new WooCommerceRestApi({
  url: process.env.REACT_APP_WOOCOMMERCE_URL ?? "",
  consumerKey: process.env.REACT_APP_WOOCOMMERCE_KEY ?? "",
  consumerSecret: process.env.REACT_APP_WOOCOMMERCE_SECRET ?? "",
  version: "wc/v3",
});

/**
 * Manages the order depending on what is registered in the userID and token
 */
export const manageOrder = (
  productAttributes?: ProductMenuAttributes,
  left?: (IImage | IText)[],
  right?: (IImage | IText)[],
  background?: string[],
  leftImage?: string,
  rightImage?: string,
  preview?: string
) => {
  let userId: string | null = localStorage.getItem("userId");
  let usertoken: string | null = localStorage.getItem("token");
  if (userId && usertoken) {
    if (productAttributes) {
      return saveOrderInDatabase(
        productAttributes,
        left,
        right,
        background,
        leftImage,
        rightImage,
        preview
      );
    } else {
      return undefined;
    }
  } else {
    return saveOrderInDatabase(
      productAttributes,
      left,
      right,
      background,
      leftImage,
      rightImage,
      preview
    ).then((res) =>
      generateFormToRedirect(
        productAttributes,
        res[0] as string,
        res[1] as string,
        res[2] as string
      )
    );
  }
};

/**
 * Generates a html form to redirect to espinilleras shopping cart with the attributes selected
 * @param productAttributes shinpad size, extra and quantity
 * @param leftUrl url to the left shinpad image
 * @param rightUrl url to the right shinpad image
 */
export const generateFormToRedirect = (
  productAttributes?: ProductMenuAttributes,
  leftUrl?: string,
  rightUrl?: string,
  preview?: string
) => {
  const isCarbon = productAttributes?.type?.toUpperCase()?.includes("CARBON");
  const productId = productAttributes?.type?.toUpperCase()?.includes("CARBON")
    ? "8554"
    : "5112";
  let form = document.createElement("form");
  form.setAttribute("method", "post");
  form.setAttribute(
    "action",
    `${process.env.REACT_APP_WOOCOMMERCE_URL}/?add-to-cart=${productId}&quantity=` +
      (productAttributes?.quantity.toString() ?? "1")
  );
  let size = document.createElement("input");
  size.setAttribute("type", "text");
  size.setAttribute(
    "name",
    isCarbon
      ? process.env.REACT_APP_WOOCOMMERCE_SIZE_CARBON ?? ""
      : process.env.REACT_APP_WOOCOMMERCE_SIZE_NORMAL ?? ""
  );
  size.setAttribute("value", productAttributes?.size.toLocaleLowerCase() ?? "");

  let iIzqda = document.createElement("input");
  iIzqda.setAttribute("type", "text");
  iIzqda.setAttribute(
    "name",
    isCarbon
      ? process.env.REACT_APP_WOOCOMMERCE_LEFT_CARBON ?? ""
      : process.env.REACT_APP_WOOCOMMERCE_LEFT_NORMAL ?? ""
  );
  iIzqda.setAttribute("value", leftUrl ?? "");

  let iDcha = document.createElement("input");
  iDcha.setAttribute("type", "text");
  iDcha.setAttribute(
    "name",
    isCarbon
      ? process.env.REACT_APP_WOOCOMMERCE_RIGHT_CARBON ?? ""
      : process.env.REACT_APP_WOOCOMMERCE_RIGHT_NORMAL ?? ""
  );
  iDcha.setAttribute("value", rightUrl ?? "");

  let extra = document.createElement("input");
  extra.setAttribute("type", "text");
  extra.setAttribute(
    "name",
    isCarbon
      ? process.env.REACT_APP_WOOCOMMERCE_EXTRA_CARBON ?? ""
      : process.env.REACT_APP_WOOCOMMERCE_EXTRA_NORMAL ?? ""
  );
  extra.setAttribute("value", productAttributes?.extra ?? "");

  let preview3d = document.createElement("input");
  preview3d.setAttribute("type", "text");
  preview3d.setAttribute(
    "name",
    isCarbon
      ? process.env.REACT_APP_WOOCOMMERCE_PREVIEW_CARBON ?? ""
      : process.env.REACT_APP_WOOCOMMERCE_PREVIEW_NORMAL ?? ""
  );
  preview3d.setAttribute("value", preview ?? "");

  const s = document.createElement("input");
  s.setAttribute("type", "submit");
  s.setAttribute("value", "Submit");

  form.appendChild(preview3d);
  form.appendChild(size);
  form.appendChild(iIzqda);
  form.appendChild(iDcha);
  form.appendChild(extra);
  form.appendChild(s);

  document.body.appendChild(form);

  form.submit();
};

/**
 * Gens an order with Woocommerce format defined in {@interface WCOrder}
 * (All parameters are optional and can be changed at any moment)
 * @param OrderStatus
 * @param paymentMethod
 * @param shippingMethod
 * @returns Order in Woocommerce format
 */
export const generateWCOrder = async (
  productAttributes: ProductMenuAttributes,
  userId: number,
  leftImage: string,
  rightImage: string
): Promise<WCOrder> => {
  const productId = productAttributes?.type ? 8554 : 5112;
  let user = await UserViewModel.getInstance().getUserById(userId);
  let address = `${user?.address?.postalCode} ${user?.address?.street} ${user?.address?.number}`;
  const data: WCOrder = {
    payment_method: "ceca",
    payment_method_title: "Credit Card",
    set_paid: false,
    status: "processing",
    billing: {
      first_name: user?.address?.name ?? "",
      last_name: user?.address?.surname ?? "",
      address_1: address,
      address_2: address,
      city: user?.address?.city ?? "",
      state: user?.address?.state ?? "",
      postcode: user?.address?.postalCode?.toString() ?? "",
      country: user?.address?.country ?? "",
    },
    shipping: {
      first_name: user?.address?.name ?? "",
      last_name: user?.address?.surname ?? "",
      address_1: address,
      address_2: address,
      city: user?.address?.city ?? "",
      state: user?.address?.state ?? "",
      postcode: user?.address?.postalCode?.toString() ?? "",
      country: user?.address?.country ?? "",
    },
    line_items: [
      {
        product_id: productId,
        quantity: productAttributes.quantity ?? 1,
        meta_data: [
          {
            key: "pa_talla",
            value: productAttributes.size.toLowerCase(),
            display_key: "Talla",
            display_value: productAttributes.size,
          },
          {
            key: "Sujeta Espinilleras",
            value: productAttributes.extra,
            display_key: "¿Neceista sujeta espinilleras?",
            display_value: productAttributes.extra,
          },
          {
            key: "Imagen Izquierda",
            value: leftImage,
            display_key: "Imagen Izquierda",
            display_value: leftImage,
          },
          {
            key: "Imagen Derecha",
            value: rightImage,
            display_key: "Imagen Derecha",
            display_value: rightImage,
          },
        ],
      },
    ],
    shipping_lines: [
      {
        method_id: "flat_rate",
        method_title: "Flat Rate",
        total: "0.00",
      },
    ],
  };
  return api.post("orders", data);
};

/**
 *
 * @param productAttributes shinpad size, needs sleeves and quantity
 * @param left left shinpad structure
 * @param right right shinpad structure
 * @param background backgorund used in the shinpads
 * @param leftUrl left shinpad result
 * @param rightUrl right shinpad result
 */
const saveOrderInDatabase = async (
  productAttributes?: ProductMenuAttributes,
  left?: (IImage | IText)[],
  right?: (IImage | IText)[],
  background?: string[],
  leftUrl?: string,
  rightUrl?: string,
  preview?: string
) => {
  let leftBase64 = await getBase64FromUrl(leftUrl!);
  let rightBase64 = await getBase64FromUrl(rightUrl!);
  let leftImage: Image = {};
  let rightImage: Image = {};
  let preview3d: Image = {};
  let random = Math.round(Math.random() * 1000);
  let backgroundImage: Image | undefined;

  if (background && !background[0].startsWith("#")) {
    let backB64 = await getBase64FromUrl(background[0]);
    await fetch(backB64! as string)
      .then((res) => res.blob())
      .then(async (blob) => {
        const file = new File([blob], `background-${random}.${background[1]}`, {
          type: `image/${background[1]}`,
        });
        backgroundImage =
          (await ImageViewModel.getInstance()
            .createImage(file)
            .catch((err) => console.log(err))) ?? {};
        background[0] = backgroundImage.id?.toString() ?? "";
      });
  }
  await fetch(leftBase64! as string)
    .then((res) => res.blob())
    .then(async (blob) => {
      const file = new File([blob], `left-${random}.png`, {
        type: "image/png",
      });
      leftImage =
        (await ImageViewModel.getInstance()
          .createImage(file, true)
          .catch((err) => console.log(err))) ?? {};
    });
  await fetch(rightBase64! as string)
    .then((res) => res.blob())
    .then(async (blob) => {
      const file = new File([blob], `right-${random}.png`, {
        type: "image/png",
      });
      rightImage =
        (await ImageViewModel.getInstance()
          .createImage(file, true)
          .catch((err) => console.log(err))) ?? {};
    });

  let previewB64 = await getBase64FromUrl(preview!);
  await fetch(previewB64 as string)
    .then((res) => res.blob())
    .then(async (blob) => {
      const file = new File([blob], `preview-${random}.png`, {
        type: "image/png",
      });
      preview3d =
        (await ImageViewModel.getInstance()
          .createImage(file, true)
          .catch((err) => console.log(err))) ?? {};
    });

  let user = UserViewModel.getInstance().loggedUser;
  if (!user && localStorage.getItem("userId")) {
    let userId = localStorage.getItem("userId");
    user = await UserViewModel.getInstance().getUserById(parseInt(userId!));
  }
  let images: Image[] = [];

  await Promise.all(
    left!.map(async (item) => {
      if ((item as IImage).src) {
        let base64 = await getBase64FromUrl((item as IImage).src);
        await fetch(base64! as string)
          .then((res) => res.blob())
          .then(async (blob) => {
            const file = new File(
              [blob],
              `${item.id}-${random}.${(item as IImage).extension}`
            );
            await ImageViewModel.getInstance()
              .createImage(file)
              .then((res) => {
                if (res) images.push(res);
              })
              .catch((err) => console.log(err));
          });
      }
    })
  );

  await Promise.all(
    right!.map(async (item) => {
      if ((item as IImage).src) {
        let base64 = await getBase64FromUrl((item as IImage).src);
        let file = new File(
          [new Blob([base64!])],
          `${item.id}-${random}.${(item as IImage).extension}`
        );
        await ImageViewModel.getInstance()
          .createImage(file)
          .then((res) => {
            if (res) images.push(res);
          })
          .catch((err) => console.log(err));
      }
    })
  );
  left?.forEach((item: IImage | IText) => {
    if (item.id.match("^image_[0-9]+$")) (item as IImage).src = "";
  });
  right?.forEach((item: IImage | IText) => {
    if (item.id.match("^image_[0-9]+$")) (item as IImage).src = "";
  });
  let structure = JSON.stringify([left ?? [], right ?? [], background]);
  let price =
    32.95 * (productAttributes?.quantity ?? 1) +
    (productAttributes?.extra == shinpadOptionals[2] ? 0 : 9.5);
  let order: Order = {
    status: OrderStatusEnum.PENDING,
    dateTimestamp: Date.now(),
    price: parseFloat(price.toFixed(2)),
    quantity: productAttributes?.quantity ?? 1,
    finalDesign: [leftImage!, rightImage!],
    address: user?.address,
    email: undefined,
    phoneNumber: undefined,
    userId: user?.id ?? undefined,
    preview3d: preview3d,
    components: {
      images: images,
      structure: structure,
      background: backgroundImage,
    },
    size: productAttributes?.size,
    extra: productAttributes?.extra,
  };
  let finalOrder = await OrdersViewmodel.getInstance().createOrder(order);
  return [
    leftImage.bucketUrl,
    rightImage.bucketUrl,
    preview3d.bucketUrl,
    finalOrder,
  ];
};
