const prefix = "REACT_APP_";
const suffix = "_"+process.env.REACT_APP_ENV

export function getEnvURL_BE():string{
    return process.env[prefix + "BE_URL" + suffix] || "";
}

export function getEnvURL_FE():string{
    return process.env[prefix + "FE_URL" + suffix] || "";
}

export function getMockActivated():boolean{
    var boolVal:String = process.env[prefix + "API_URL" + suffix] || "";
    return boolVal === 'true';
}

export function getEnvURL_WOOCOMMERCE():string{
    return process.env["WOOCOMMERCE_" + "URL" + suffix] || "";
}

export function getEnvKEY_WOOCOMMERCE():string{
    return process.env["WOOCOMMERCE_" + "KEY" + suffix] || "";
}

export function getEnvSECRET_WOOCOMMERCE():string{
    return process.env["WOOCOMMERCE_" + "SECRET" + suffix] || "";
}